import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO, AllPosts, CategoryNav } from 'components';
import './blog.scss';

export const BlogCategory = props => {
  const { location, data, pageContext } = props;
  // const categoryPosts = data.allPrismicBlogPost.nodes;
  const { posts } = data;
  const { nodes } = posts;
  const { category } = pageContext;

  // destructuring query

  const paginationAmount = 10;

  // search bar

  const [visiblePosts, setVisiblePosts] = useState(nodes ? [...nodes].splice(0, paginationAmount) : []); // set visible posts
  const [numberOfPosts, setNumberOfPosts] = useState(paginationAmount); // set search query

  const handleLoadMore = () => {
    const newNumberOfPosts = numberOfPosts + paginationAmount;
    setNumberOfPosts(newNumberOfPosts);
    setVisiblePosts([...nodes].splice(0, newNumberOfPosts));
  };

  return (
    <Layout location={location}>
      <SEO title={`${category} Posts | Lemmy`} />
      <div className="blog wrapper">
        <div className="blog-title-wrapper">
          <h1 className="blog-title">{category}</h1>
        </div>
        <CategoryNav active={category} />
        <AllPosts posts={visiblePosts} onLoadMore={handleLoadMore} numberOfPosts={numberOfPosts} />
      </div>
    </Layout>
  );
};

export default BlogCategory;

export const pageQuery = graphql`
  query($category: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    posts: allPrismicBlogPost(filter: { data: { category: { eq: $category } } }) {
      nodes {
        uid
        id
        prismicId
        data {
          category
          blog_post_title {
            html
          }
          date_published
          hero_image {
            url
          }
          hero_background_color
          post_excerpt {
            text
          }
        }
      }
    }
  }
`;
